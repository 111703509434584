@import "custom/index";
@import "element/index";
@import "transition";
@import "variables";
@import "sidebar";
@import "common";
@import "echarts";

@font-face {
  font-family: 'iconfont';  /* project id 1211000 */
  src: url('//at.alicdn.com/t/font_1211000_opald5hgjjd.eot');
  src: url('//at.alicdn.com/t/font_1211000_opald5hgjjd.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_1211000_opald5hgjjd.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_1211000_opald5hgjjd.woff') format('woff'),
  url('//at.alicdn.com/t/font_1211000_opald5hgjjd.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_1211000_opald5hgjjd.svg#iconfont') format('svg');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.router-link-exact-active, .router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.spacer {
  flex: 1;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 1rem;
}










