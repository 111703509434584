@import "table";
.el-menu {
	border: 0;
}

.el-submenu__icon-arrow {
  display: none;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}


.el-loading-mask {
  z-index: 500;
}

.el-button {
  span {
    vertical-align: middle;
  }
}

.el-dialog__body {
  padding: 0 20px 20px 20px;
}
